import React, { useEffect, useState } from 'react'
import Layout from '../components/layouts'
import { Footer } from '../components/shareable/footer/Footer'
import { Flex, Text } from '../components/primitives'
import { getOrderFromKlarnaId } from '../api/getOrderFromKlarnaId'
import { LoadingSpinner } from '../components/shareable/lottie/LoadingSpinner'

const getPrice = (respone) => {
  if (respone && respone.checkout && respone.checkout.order_amount) {
    return Number(respone.checkout.order_amount) / 100
  }
  return 0
}
export default ({ navigate }) => {
  const [modalIsOpen, setIsModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const klarnaId = new URLSearchParams(window.location.search).get('klarnaId')
    const getOrder = async () => {
      setLoading(true)
      const respone = await getOrderFromKlarnaId({ klarnaId })
      setLoading(false)

      try {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'transaction',
          transactionId: klarnaId,
          transactionTotal: getPrice(respone)
        })
      } catch (e) {
        console.log(e)
      }
    }

    getOrder()
  }, [])

  return (
    <Layout
      blackNav={true}
      modelOpenProps={modalIsOpen}
      onCloseModal={() => {
        setIsModalOpen(false)
      }}
    >
      <Flex jusifyContent={'center'}>
        <Flex
          mt={['80px', '80px', '120px']}
          maxWidth={'1000px'}
          minHeight={'100vh'}
          p={4}
          flexDirection={'column'}>
          {loading ? (
            <Flex
              mt={4}
              width={'100%'}
              jusifyContent={'center'}
              height={'40px'}>
              <Flex width={'100px'} height={'40px'}>
                <LoadingSpinner />
              </Flex>
            </Flex>
          ) : (
            <>
              <Text
                textAlign={'center'}
                fontWeight={'bold'}
                fontSize={8}
                mb={4}>
                Tack för att du valt Hemset!
              </Text>
              <Text textAlign={'center'} mb={2}>
                Vi ser fram emot att hjälpa dig få ett trivsamt hem!
              </Text>
              <Text textAlign={'center'}>
                En inredare kommer kontakta dig inom 1-4 dagar.
              </Text>
            </>
          )}
        </Flex>
      </Flex>
      <Footer
        actionClick={() => {
          setIsModalOpen(true)
        }}
      />
    </Layout>
  )
}
